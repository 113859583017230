import React, { Component } from 'react'


class CoreFeatures extends Component {

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="usr-admin-tab pt-100 mb-0">
        <div className='gray-bg'>
        <div className="container">
            <h2 className="heading-h2 text-center"><span className="heading-h3"><span className="bluecolor">Features We Provide</span></span>
            In Our Crypto Launchpad Development services</h2>
            <p className='text-center'>We, at Coinclone design launchpad with advanced-level features for both user and admin dashboards. </p>
          <div className="row">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  User Panel
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Admin Panel
                </li>
              </ul>

              <div className="tab-content px-0">
                <div id="tab1" className="tab-pane tabs_items active">
                    <div className='d-flex flex-wrap justify-content-center'>
                    <div className='user-panel'>
                      <h3>Wallet Integration</h3>
                      <p>Our launchpad comes with a premium-level wallet integration option. Users can simply connect their wallets to buy or purchase the crypto tokens. Add-on to that, our wallet is compatible.</p>
                    </div>
                    <div className='user-panel'>
                      <h3>Multi-tiered Staking Modules</h3>
                      <p>This launchpad is for Investors who want to buy the newly launched tokens through various rounds or tiers. This enhances the hype, supply, and popularity of the new tokens. Each tier has its unique rewards and features.</p>
                    </div>
                    <div className='user-panel'>
                      <h3>Blockchain Compatability</h3>
                      <p>We offer an exponential crypto launchpad that comes with various blockchain-compatible features. It supports various blockchain networks for token purchases. A wide range of blockchain users can purchase your tokens. </p>
                    </div>
                    <div className='user-panel'>
                      <h3>Liquidity Pools </h3>
                      <p>As fundraising models are early-stage investments for users, we offer these potential liquidity pools in the launchpad. Users can invest their tokens and get returns easily. This paves the way for an enhanced trading experience within the Launchpad platform. </p>
                    </div>
                    <div className='user-panel'>
                      <h3>Multiple Payment Option </h3>
                      <p>To enable a seamless transaction, we have integrated this multiple payment feature. Along with this, users can pay for the tokens in various modes. This feature maximizes the accessibility for buying the tokens in the launchpad.</p>
                    </div>
                    <div className='user-panel'>
                      <h3>Customer Support </h3>
                      <p>One of the essential features of our launchpad is the 24/7 support system. Users can raise tickets to resolve their transaction issues and other related problems. Additionally, it helps to monitor the regular activities. </p>
                    </div>
                    </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                    <div className='d-flex flex-wrap justify-content-center'>
                    <div className='admin-panel'>
                      <h3>KYC Compliance</h3>
                      <p>The admin or the startup can regulate, monitor, approve, and check the new user’s actions. Inappropriate users are restricted at the initial stage. Hence, your Launchpad website would become a hub for trustworthiness. </p>
                    </div>
                    <div className='admin-panel'>
                      <h3>Crypto Token Launch</h3>
                      <p>After creating the tokens, the admin can launch them in the Launchpad flawlessly. The token value, distribution, total supply of tokens, and other details can be easily gathered. </p>
                    </div>
                    <div className='admin-panel'>
                      <h3>Real-time Analytics </h3>
                      <p>This feature enables the admin to monitor the platform sustainably. The admin can analyze user transactions, new user approval, and other relevant details. It also helps to identify susceptible activities in the launchpad. </p>
                    </div>
                    <div className='admin-panel'>
                      <h3>Code Auditing</h3>
                      <p>The admin would have access to change or modify the technical front-end & back-end code of the website. When an inconvenient technical problem arises, the admin can make changes and rectify them.</p>
                    </div>
                    <div className='admin-panel'>
                      <h3>Numerous fundraising models</h3>
                      <p>We have designed our crypto launchpad to support various fundraising models like ICO, IDO, IGO, etc. So that everyone who needs to execute a project would approach you. It helps you to reap revenue from numerous sources. </p>
                    </div>
                    <div className='admin-panel'>
                      <h3>Project Management</h3>
                      <p>The admin will have access to monitor the latest projects and their outcomes. They can also review, approve, and reject a project based on the website’s policy and norms. This feature helps to identify fake projects and reduces the chance of project scamming.</p>
                    </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures